thead > tr > th {
    background-color: #173676 !important;
    color : white !important; 
    text-align: center !important;
}

tbody > tr > td {
    cursor: pointer;
}

.ant-table tbody > tr:hover > td {
    background: rgb(187, 225, 255) !important;
}

.ant-table-pagination {
    justify-content: center !important;
}

.ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    position: absolute;
    top: 50%;
    right: 0;
    width: 1px;
    height: 1.6em;
    background-color: rgba(255, 255, 255, 0.4);  /* 테이블 헤더의 구분자 */
    transform: translateY(-50%);
    transition: background-color 0.3s;
    content: '';
}

.UserList thead > tr > th {
    background-color: #ff5757 !important;
    color : white !important; 
    text-align: center !important;
}


.ant-descriptions-item-label {
    font-weight: bold;
    color: #666666 !important;
}

.ant-descriptions-item-content {
    color: #333333 !important;
}

/* Scroll bar Setting */ 
.ant-table-body {
    height: 100%;
    overflow-y: scroll;
}
.ant-table-body::-webkit-scrollbar {
    width: 10px;
    border-radius: 6px;
}
.ant-table-body::-webkit-scrollbar-thumb {
    background-color: #289bf9;
    border-radius: 6px;
}
.ant-table-body::-webkit-scrollbar-track {
    background-color: #a9daff;
    border-radius: 6px;
}

.ui.button.operationButton {
    background-color: #efefef !important;
    color: #000 !important;
    border:solid 1px #777777 !important
}
.ui.button.operationButton:hover {
    background-color: #ccc !important;
}