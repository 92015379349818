.ant-drawer-content {
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
}

.ant-drawer-header {
    background-color: #40a9ff !important;
}

.ant-drawer-title {
    color: white !important
}

.ant-drawer-close {
    color: white !important
}

.ant-descriptions-item-label {
    background-color: #d1eafd !important;
}


/* Scroll bar Setting */ 
.ant-drawer-body {
    height: 100%;
    overflow-y: scroll;
}
.ant-drawer-body::-webkit-scrollbar {
    width: 10px;
    border-radius: 6px;
}
.ant-drawer-body::-webkit-scrollbar-thumb {
    background-color: #40a9ff;
    border-radius: 6px;
}
.ant-drawer-body::-webkit-scrollbar-track {
    background-color: #80c9ff;
    border-radius: 6px;
}
