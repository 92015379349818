.ant-tabs-card.ant-tabs-left > .ant-tabs-nav .ant-tabs-tab-active, .ant-tabs-card.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-tab-active {
    border-radius: 5px 0 0 5px !important;
    border : solid 1px #173676;
    color: #fff !important;
    background: #173676 !important;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #fff !important;
}

.ant-tabs-left > .ant-tabs-content-holder > .ant-tabs-content > .ant-tabs-tabpane, .ant-tabs-left > div > .ant-tabs-content-holder > .ant-tabs-content > .ant-tabs-tabpane {
    border-top: solid 1px #cfcfcf;
    border-bottom: solid 1px #cfcfcf;
    border-right: solid 1px #cfcfcf;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    padding: 24px !important;
}

.ant-tabs-left > div.ant-tabs-content-holder > div {
    border-left : solid 1px #cfcfcf;
}

.ant-tabs-content-holder {
    background-color: #fff;
    border-radius: 10px;
}